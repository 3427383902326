import React from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import Hero from '../hero';
import BackgroundPattern from '../background-pattern';
import skills from './skills';
import Progress from './progress';
import Header from '../header';
import './resume.css';

class Resume extends React.Component {
  constructor (props) {
    super(props);

    this.state = {};
    this.skills = skills;
  }

  render () {
    return (
      <main>
        <Header></Header>
        <section className="resume-skills">
          <article className="my-skills">
            <ul className="ms-items">
              <li className="ms-item">
                <div className="ms-item-title">
                  <h4>Front-End</h4>
                </div>
                <div className="ms-item-skills">
                  <ul>
                    {this.skills.frontend.map((item, indx) => {
                      return (
                        <li key={indx}>
                          <div className="skill-name"><span>{item.skill}</span><span>{item.perct}%</span></div>
                          <Progress width={item.perct}></Progress>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </li>
              <li className="ms-item">
                <div className="ms-item-title">
                  <h4>Back-End</h4>
                </div>
                <div className="ms-item-skills">
                  <ul>
                    {this.skills.backend.map((item, indx) => {
                      return (
                        <li key={indx}>
                          <div className="skill-name"><span>{item.skill}</span><span>{item.perct}%</span></div>
                          <Progress width={item.perct}></Progress>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </li>
              <li className="ms-item other-skills">
                <div className="ms-item-title">
                  <h4>Other Skills</h4>
                </div>
                <div className="ms-item-skills">
                  <img src="/images/resume/other-skills.png" />
                </div>
              </li>
              {/*
              <li className="ms-item-resume">
                <a target="_blank" href="/files/terrell_weaver.pdf" className="view-resume-btn"><span>View Resume</span></a>
              </li>
              */}
            </ul>
          </article>
        </section>
        <BackgroundPattern></BackgroundPattern>
      </main>
    );
  }
}

export default Resume;
