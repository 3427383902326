import React from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Project from './project';
import Portfolio from './portfolio';
import About from './about';
import Resume from './resume';
import logo from './logo.svg';
import './Reset.css';
import './Fonts.css';
import "animate.css/animate.min.css";
import './App.css';

class App extends React.Component {
  constructor (props) {
    super(props);

    this.state = {};
  }

  render () {
    return (
      <Router>
        <Switch>
          <Route exact path='/' component={Portfolio}/>
          <Route exact path='/resume-skills' component={Resume}/>
          {/*<Route exact path='/project/:projectname' component={Project}/>
          <Route exact path='/about' component={About}/>*/}
        </Switch>
      </Router>
    );
  }
}

export default App;
