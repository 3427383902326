import React from 'react';
import { NavLink, Link, withRouter } from "react-router-dom";
import ScrollAnimation from 'react-animate-on-scroll';

class Header extends React.Component {
  constructor (props) {
    super(props);

    this.state = {};
  }

  render () {
    const goBack = () => {
      this.props.history.goBack();
    };

    return (
        <header className={this.props.className}>
          <div>
            <div className="back-link">
            {this.props.hasBack && 
              (<span onClick={() => goBack()}>&#8592;</span>)
            }
            </div>
            <div className="header-links">
              <div className="header-link"><NavLink exact={true} activeClassName='active' to='/'>Portfolio</NavLink></div>
              <div className="header-link"><NavLink exact={true} activeClassName='active' to='/resume-skills'>Skills</NavLink></div>
              {/*<div className="header-link"><NavLink exact={true} activeClassName='active' to='/about'>About</NavLink></div>*/}
            </div>
          </div>
        </header>
    );
  }
}

export default withRouter(Header);
